<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="card bd-0 shadow-base pd-10">
                    <div class="card-header bg-transparent pd-0 bd-b-0">
                        <h5 class="tx-14 tx-uppercase tx-spacing-1 tx-semibold">Filter Vehicles</h5>
                    </div><!-- card-header -->
                    <div class="card-block pd-0">

                        <div class="row row-sm mg-t-10">
                            <div class="col-3 mg-t-10 mg-lg-t-0">
                                <div class="form-group mg-b-0">
                                    <label>Plate</label>
                                    <input type="text" v-on:keyup.enter="search(1, true)" v-model="searchTerms.plate" class="form-control form-control-dark tx-14" placeholder="Plate">
                                </div><!-- form-group -->
                            </div><!-- col-2 -->
                        </div><!-- row -->

                        <submit-button @click="search(1, true)" :background="'btn-info tx-11 tx-spacing-1 tx-uppercase tx-semibold tx-mont pd-y-12 pd-x-30 mg-t-10'" :text="'Search Vehicle'" :clicked="searching" />
                    </div><!-- card-block -->
                </div>
            </div>
        </div>
        <div class="row mg-t-20">
            <div class="col-lg-12">

                <p v-if="error" class="tx-24 tx-bold tx-danger tx-uppercase">{{errorMsg}}</p>

                <pagination v-bind:data="blacklist" @search="search"></pagination>

                <table width="100%" class="table table-striped table-hover tx-white">
                    <thead class="thead-colored thead-primary">
                    <tr>
                        <th>#</th>
                        <th>Registration</th>
                        <th>Make</th>
                        <th>Model</th>
                        <th>Reason</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="veh in blacklist.data" v-if="!searching" style="cursor: pointer">
                        <th @click="loadVehicle(veh.vehicle.plate)">{{veh.vehicle_id}}</th>
                        <td @click="loadVehicle(veh.vehicle.plate)">{{veh.vehicle.plate}}</td>
                        <td @click="loadVehicle(veh.vehicle.plate)">{{veh.vehicle.make}}</td>
                        <td @click="loadVehicle(veh.vehicle.plate)">{{veh.vehicle.model}}</td>
                        <td @click="loadVehicle(veh.vehicle.plate)">{{veh.reason}}</td>
                        <th><button class="btn btn-danger btn-sm" @click="removeBlacklist(veh.vehicle.plate)">Remove</button></th>
                    </tr>
                    <tr v-if="blacklist.data.length < 1 && !searching">
                        <td colspan="3" class="text-center">No Blacklisted Vehicles</td>
                    </tr>
                    <tr v-if="searching">
                        <td colspan="3" class="text-center">
                            <div class="spinner-border text-secondary m-2" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>
        </div>
        <floating-button @click="click"></floating-button>
    </div>
</template>

<script>
import FloatingButton from "@/components/FloatingButton";
import Pagination from "@/components/Pagination";
import SubmitButton from "@/components/SubmitButton";
import Swal from "sweetalert2";
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    name: "Blacklist",
    components: {SubmitButton, FloatingButton, Pagination},
    data(){
        return {
            searching: true,
            blacklist: {
                data: [],
                currPage: 1,
                totalResults: 0,
                from: 0,
                to: 0,
                perPage: 0,
                lastPage: 1
            },
            searchTerms: {
                plate: null,
            },
            error: false,
            errorMsg: ""
        }
    },
    mounted(){
        this.search(1);
    },
    methods: {
        loadVehicle(id){
            this.$router.push(`/vehicles/${id}`);
        },
        search(page, filtered = false){
            this.error = false;
            let url = `https://server.varsanpr.com/api/blacklist?page=${page}`;
            let query = [];
            query.push({name: "client_id", value: this.$store.state.auth.user.selectedClient});
            if(filtered){
                if(this.searchTerms.plate !== null && this.searchTerms.plate !== undefined){
                    query.push({name: "registration", value: this.searchTerms.plate});
                }
            }

            if(query.length > 0){
                let queryString = "&";
                query.forEach((q) => {
                    queryString = `${queryString}${q.name}=${q.value}&`;
                });
                queryString = queryString.slice(0, -1);
                url = `${url}${queryString}`;
            }
            this.searching = true;
            axios.get(url, {
                headers: authHeader()
            })
                .then(response => {
                    this.blacklist.data = response.data.blacklist;
                    this.blacklist.currPage = response.data.pagination.page;
                    this.blacklist.totalResults = response.data.pagination.total;
                    this.blacklist.from = (this.blacklist.currPage - 1) * response.data.pagination.showing + 1;
                    this.blacklist.to = this.blacklist.from + response.data.pagination.showing;
                    this.blacklist.perPage = response.data.pagination.showing;
                    this.blacklist.lastPage = response.data.pagination.lastPage;
                    this.searching = false;
                })
                .catch(error => {
                    this.$error('Failed to process Search request. Please try again later.', error);
                    this.searching = false;
                });
        },
        click(){
            this.$router.push({name: 'createBlacklist'});
        },
        removeBlacklist(plate){
            Swal.fire({
                title: 'Are you sure?',
                text: "This will remove the vehicle from your blacklist?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, remove it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`https://server.varsanpr.com/api/blacklist`, {
                        data: {
                            client_id: this.$store.state.auth.user.selectedClient,
                            registration: plate
                        },
                        headers: authHeader()
                    })
                        .then(response => {
                            if(response.data.success){
                                Swal.fire(
                                    'Deleted!',
                                    'This vehicle has been removed from the blacklist.',
                                    'success'
                                )
                                this.search(this.blacklist.currPage, true);
                            }else{
                                throw new Error('Failed to assign error.');
                            }
                        })
                        .catch(error => {
                            Swal.fire(
                                'Oops!',
                                'Unable to remove from blacklist.',
                                'error'
                            )
                        });
                }
            })
        }
    }
}
</script>

<style scoped>

</style>
